
import { defineComponent, ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import PageStep from '@/components/layout/PageStep.vue'
import WizardStepBlockContainer from '@/components/pages/auth/wizard/WizardStepBlockContainer.vue'
import { wizardSteps } from '@/definitions/auth/data'

export default defineComponent({
  components: {
    PageStep,
    WizardStepBlockContainer,
  },
  props: {
    stepSize: {
      type: String,
    },
  },
  setup() {
    const currentIndex = ref(2)
    const route = useRoute()
    const country = computed(() => route.meta.country)

    const wizard = ref([
      {
        icon: 'tmi-phone-globe',
        title: 'Toll-free number',
        titleBadge: 'Registration Required',
        description: '8XX prefix numbers for U.S. and Canada verified for sending texts by the messaging aggregator.',
        list: [
          'Brand & campaign registration <strong>required</strong>',
          'Number cost: <strong>$10 per month</strong>',
          'Traffic verified with the SMS aggregator',
          'Approval period <strong>up to 5 weeks</strong>',
          '2-way SMS, MMS and voice supported',
          'Minimum initial credit required: $20',
        ],
        buttonText: 'Get started',
        link: { name: 'wizard.' + country.value + '.senderSettings', params: { numberType: 'toll-free' } },
      },
      {
        icon: 'tmi-phone-geo',
        title: 'Local Canadian number',
        description: '10-digit local Canadian numbers that do not require verification by the local network carriers.',
        list: [
          'Brand & campaign registration <strong>not required</strong>',
          'Number cost: <strong>$10 per month</strong>',
          'No verification required',
          '<strong>Instant activation</strong>',
          '2-way SMS, MMS and voice supported',
          'Minimum initial credit required: $20',
        ],
        buttonText: 'Get started',
        link: { name: 'wizard.' + country.value + '.senderSettings', params: { numberType: 'local' } },
      },
    ])

    return {
      wizardSteps,
      currentIndex,
      wizard,
    }
  },
})
